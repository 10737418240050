<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="项目名称" prop="projectName">
        <a-input v-model="form.projectName" placeholder="请输入项目名称" :disabled="isEdit && form.projectId != undefined"/>
      </a-form-model-item>
      <a-form-model-item label="DIY模块项目ID" prop="projectId">
        <a-input v-model="form.projectId" placeholder="请输入DIY模块项目ID" />
      </a-form-model-item>
      <a-form-model-item label="联系人姓名" prop="contactsName">
        <a-input v-model="form.contactsName" placeholder="请输入联系人姓名" :disabled="isEdit && form.projectId != undefined"/>
      </a-form-model-item>
      <a-form-model-item label="联系人电话" prop="contactsPhone">
        <a-input v-model="form.contactsPhone" placeholder="请输入联系人电话" :disabled="isEdit && form.projectId != undefined"/>
      </a-form-model-item>
      <a-form-model-item label="数据库IP" prop="dbIp">
        <a-input v-model="form.dbIp" placeholder="请输入数据库IP"  allow-clear />
      </a-form-model-item>
      <a-form-model-item label="数据库端口" prop="dbPort">
        <a-input v-model="form.dbPort" placeholder="请输入数据库端口"  allow-clear />
      </a-form-model-item>
      <a-form-model-item label="数据库名称" prop="databaseName">
        <a-input v-model="form.databaseName" placeholder="请输入数据库名称"  allow-clear />
      </a-form-model-item>
      <a-form-model-item label="数据库用户名" prop="dbName">
        <a-input v-model="form.dbName" placeholder="请输入数据库用户名"  allow-clear />
      </a-form-model-item>
      <a-form-model-item label="数据库密码" prop="dbPwd">
        <a-input v-model="form.dbPwd" placeholder="请输入数据库密码"  allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { getProject, addProject, updateProject } from '@/api/system/diy'

export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: undefined,
        projectId: undefined,
        projectName: undefined,
        contactsName: undefined,
        contactsPhone: undefined,
        databaseName:undefined,
        dbName: undefined,
        dbPwd: undefined,
        dbIp: undefined,
        dbPort: 0
      },
      open: false,
      isEdit:false,
      rules: {
        projectName: [{ required: true, message: '项目名称不能为空', trigger: 'blur' }],
        contactsName: [{ required: true, message: '联系人姓名不能为空', trigger: 'blur' }],
        contactsPhone: [
          { required: true, message: '联系人手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  filters: {
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        projectId: undefined,
        projectName: undefined,
        contactsName: undefined,
        contactsPhone: undefined,
        dbName: undefined,
        dbPwd: undefined,
        dbIp: undefined,
        dbPort: undefined
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.open = true
      this.formTitle = '添加DIY项目信息'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.isEdit = true;
      this.reset()
      const id = row ? row.id : ids
      getProject(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改DIY项目信息'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined) {
            updateProject(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addProject(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
